@media only screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}

/* argument */
/* site style */
/* [ opacity ]
-------------------------------------------------*/
/* [ display style ]
-------------------------------------------------*/
/* [ background-image ]
-------------------------------------------------*/
/* [ writing-mode ]
-------------------------------------------------*/
/* [ illustrator & photoshop letter spacing ]
-------------------------------------------------*/
/* [ easy breakpoint ]
-------------------------------------------------*/
/* [ easy transform ]
-------------------------------------------------*/
/* [ writing-mode ]
-------------------------------------------------*/
body#notfound .notice {
  padding: 100px 0;
}
body#notfound .notice .inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}
body#notfound .notice .inner .notfound-ttl {
  font-size: 32px;
  font-size: 3.2rem;
  font-family: "Inter", sans-serif;
}
body#notfound .notice .inner .notfound-txt {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.7em;
  text-align: center;
}