@use "common" as *;

body#notfound{

    .notice{
        padding:100px 0;
        .inner{
            display: flex;
            align-items: center;
            flex-direction: column;
            gap:50px;
            .notfound-ttl{
                @include font-size(32);
                font-family: $inter;
            }
            .notfound-txt{
                @include font-size(20);
                line-height: 1.7em;
                text-align: center;
            }
        }
    }

}